import { Canvas, useFrame } from "@react-three/fiber";
import { useGLTF, useAnimations, OrbitControls } from "@react-three/drei";
import { Suspense, useState, useEffect, useRef } from "react";
import modelPath from "../assets/models/jonesy_fortnite.glb";
import { AnimationMixer } from "three";
import { Object3D } from "three";

function AnimatedModel({
  url,
  foldArms,
  onLoad,
}: {
  url: string;
  foldArms?: boolean;
  onLoad: () => void;
}) {
  const group = useRef();
  const lightTarget = useRef(new Object3D());
  const { scene, animations } = useGLTF(url);
  const mixer = useRef<AnimationMixer | undefined>();

  useEffect(() => {
    if (animations && group.current) {
      mixer.current = new AnimationMixer(group.current);
      const action = foldArms
        ? mixer.current.clipAction(animations[2])
        : mixer.current.clipAction(animations[0]);
      action.play();
      onLoad(); // Call onLoad when the model is ready
    }

    return () => {
      mixer.current?.stopAllAction();
    };
  }, [animations, foldArms, onLoad]);

  useFrame((state, delta) => {
    mixer.current?.update(delta);
  });

  return (
    <>
      <primitive
        ref={group}
        scale={0.00017}
        object={scene}
        position={[0.5, -1.24, 0]}
      />
    </>
  );
}

export default function FortniteViewer({ foldArms }: { foldArms?: boolean }) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="h-[99%] w-screen relative bg-transparent overflow-visible">
      {isLoading && <></>}
      <Canvas
        style={{
          backgroundColor: "transparent",
        }}
        camera={{ position: [0, 1, 9], fov: 20 }}
      >
        <Suspense fallback={null}>
          <ambientLight intensity={2.3} />
          <pointLight position={[0, 2, 1]} intensity={5} />
          <AnimatedModel
            url={modelPath}
            foldArms={foldArms}
            onLoad={() => setIsLoading(false)}
          />
        </Suspense>
      </Canvas>
    </div>
  );
}

// Pre-load the model
useGLTF.preload(modelPath);
