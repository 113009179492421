export default function SearchIcon({
  fillColor,
  size,
}: {
  fillColor: string;
  size: number;
}) {
  return (
    <svg
      className={`${fillColor}`}
      width={`${size}`}
      height={`${size}`}
      viewBox="0 0 171 176"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M112.495 145.349C101.873 150.876 89.8015 154 77 154C34.4741 154 0 119.526 0 77C0 34.4741 34.4741 0 77 0C119.526 0 154 34.4741 154 77C154 92.3841 149.488 106.714 141.716 118.74L171 150L142.243 176L112.495 145.349ZM117 77C117 99.0914 99.0914 117 77 117C54.9086 117 37 99.0914 37 77C37 54.9086 54.9086 37 77 37C99.0914 37 117 54.9086 117 77Z"
      />
    </svg>
  );
}
