import React, {
  useState,
  useEffect,
  useRef,
  KeyboardEvent,
  useCallback,
} from "react";
import { ChevronRight } from "lucide-react";
import { LoadingDots } from "../components/loading-dots/loading";
import { motion } from "framer-motion";
import { ColorRing } from "react-loader-spinner";

import OpenAI from "openai";
import ChevronRightFortnite from "./chevron";
import HoverButton from "../components/hoverButton";

const openai = new OpenAI({
  apiKey: process.env["REACT_APP_OPENAI_API_KEY"],
  dangerouslyAllowBrowser: true,
});

const RESPONSE_LENGTH_IN_WORDS = 115;
const FORNITE_SYSTEM_PROMPT = `You are FortniteGPT. Your role is to take any input and translate it into Fortnite terminology. Every response you give should describe the input in terms related to Fortnite, its gameplay, characters, items, or strategies. Use metaphors, references to in-game items, locations, or mechanics to explain the input. Make sure to stay true to Fortnite's vocabulary and style. Please limit your responses to ${RESPONSE_LENGTH_IN_WORDS} words.`;

const TICKER_NAMES = ["FNTERMS", "FN_TERMS", "FN-TERMS", "$TERMS"];

export const ChatUI: React.FC = () => {
  const [message, setMessage] = useState<string>("");
  const [input, setInput] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const textareaRef = useRef(null);
  const isFirstSendRef = useRef(true);

  const callGPT = useCallback((messageToPrompt: string) => {
    async function fetchData() {
      try {
        const completion = await openai.chat.completions.create({
          model: "gpt-4o-mini",
          messages: [
            { role: "system", content: FORNITE_SYSTEM_PROMPT },
            {
              role: "user",
              content: messageToPrompt,
            },
          ],
        });

        const output = completion.choices[0].message.content;

        setLoading(false);
        setMessage(output || "");
      } catch (error) {
        console.log("ERROR");
      }
    }

    fetchData();
  }, []);

  // Debounced sendMessage function with initial delay exclusion
  const debouncedSendMessage = useCallback(
    (messageToPrompt: string, isFirstSend: boolean) => {
      setLoading(true);

      // Hard-coded responses
      if (
        TICKER_NAMES.find((ticker) =>
          messageToPrompt.toUpperCase().includes(ticker)
        )
      ) {
        setTimeout(() => {
          setLoading(false);
          setMessage(
            `Yo, listen up, Looters! 🪙 Introducing the $${TICKER_NAMES[0]}, the ultimate Victory Royale in the crypto battle bus! Just like grabbing that Mythic chest at Tilted Towers, this meme coin is your golden ticket to the vault of gains! Picture stacking those V-Bucks as the price surges—this is your Legendary loot!  It’s like building your ultimate loadout, but for your wallet. Don’t be a Default in the crypto realm; jump in now and ride the wave! Invest in $${TICKER_NAMES[0]}—it’s a llama full of potential waiting to be unleashed! 🚀🦙`
          );
        }, 2000);
      } else {
        // Call GPT with the input immediately if it's the first send
        if (isFirstSend) {
          callGPT(messageToPrompt);
        } else {
          // Otherwise, call GPT with a delay
          const delay = 4000; // 4 seconds debounce delay
          setTimeout(() => {
            callGPT(messageToPrompt);
          }, delay);
        }
      }
    },
    [callGPT, setLoading, setMessage] // Dependencies for the debounced function
  );

  const sendMessage = () => {
    if (input.trim() === "") return;
    const gptPrompt = input;

    setInput("");

    // Call the debounced sendMessage with isFirstSend flag
    debouncedSendMessage(gptPrompt, isFirstSendRef.current);
    isFirstSendRef.current = false; // Set isFirstSendRef to false after the first send
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    sendMessage();
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default action (new line) if needed
      sendMessage();
    }
  };

  const setTextAreaHeight = () => {
    if (textareaRef.current) {
      // @ts-ignore
      textareaRef.current.style.height = "auto"; // Reset the height
      // @ts-ignore
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set new height
    }
  };

  // If either window resize or input change causes a newline, set newLine
  useEffect(() => {
    function handleResize() {
      setTextAreaHeight();
    }

    window.addEventListener("resize", handleResize);

    setTextAreaHeight();
    return () => window.removeEventListener("resize", handleResize);
  }, [input]);

  return (
    <div className="flex flex-col w-full h-full">
      <form onSubmit={handleSubmit} className="flex space-x-2">
        <div
          className="
            flex-1 rounded-lg 
            flex items-center
            backdrop-blur-md bg-black/30
            font-sans font-bold text-sm
            mb-4
            "
        >
          <textarea
            ref={textareaRef}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyPress}
            className="
            p-4
            bg-transparent w-full focus:outline-none
            text-white overflow-hidden resize-none
            font-UI-secondary text-[21px] leading-[22px]
            placeholder:translate-y-[1px]
            placeholder:text-white/30
            "
            placeholder="Translate anything into Fortnite terms..."
            rows={1}
          />
          <div className="flex h-full place-items-center">
            <HoverButton
              onClick={handleSubmit}
              color="white"
              classNameStyles="p-1 bg-white text-black font-bold rounded-lg mr-[10px]"
              disabled={loading}
            >
              {loading ? (
                <div
                  style={{
                    transform: "scale(1.5)", // Adjust the scale as needed
                    transformOrigin: "center",
                  }}
                >
                  <ColorRing
                    width={28}
                    height={28}
                    colors={[
                      "#e15b64",
                      "#f47e60",
                      "#f8b26a",
                      "#abbd81",
                      "#849b87",
                    ]}
                  />
                </div>
              ) : (
                // <ChevronRight size={20} strokeWidth={4} />
                <ChevronRightFortnite fillColor="black" size={28} />
              )}
            </HoverButton>
          </div>
        </div>
      </form>
      {/* If theres a response from gpt to display or we're loading, display a response bubble */}
      {(message !== "" || loading) && (
        <>
          <div className="flex-1 overflow-y-auto mb-4 space-y-4 mt-[10px]">
            {loading && (
              <div className="flex">
                <div
                  className={`max-w-xs md:max-w-md p-[12px] px-[18px] rounded-lg bg-black/50 text-white backdrop-blur-md`}
                >
                  <div className="flex justify-start">
                    <LoadingDots />
                  </div>
                </div>
              </div>
            )}
            {!loading && (
              <div
                className={`w-10/12 p-[12px] px-[18px] rounded-lg bg-black/50 text-white backdrop-blur-md`}
              >
                {loading ? (
                  <div className="flex justify-start">
                    <LoadingDots />
                  </div>
                ) : (
                  <TypewriterText text={message} />
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const TYPEWRITER_SPEED = 15;

const TypewriterText: React.FC<{ text: string }> = ({ text }) => {
  const [displayText, setDisplayText] = useState<string>("");
  const currentTextRef = useRef("");

  useEffect(() => {
    let i = 0;
    const typingInterval = setInterval(() => {
      if (i < text.length) {
        currentTextRef.current += text.charAt(i);
        setDisplayText(currentTextRef.current);
        i++;
      } else {
        clearInterval(typingInterval);
      }
    }, TYPEWRITER_SPEED);

    return () => clearInterval(typingInterval);
  }, [text]);

  return (
    <div className="font-UI-primary text-[24px] tracking-wide">
      {displayText}
    </div>
  );
};
