export default function ChevronRightFortnite({
  fillColor,
  size,
}: {
  fillColor: string;
  size: number;
}) {
  return (
    <div style={{ height: `${size}px`, width: `${size}px` }}>
      <div
        className={`h-full w-full rounded-full bg-white flex flex-col justify-center items-center`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          fill={fillColor}
        >
          <path
            fill-rule="evenodd"
            d="M15.058 6.329a1.2 1.2 0 0 0-1.697 0l-2.546 2.545a1.2 1.2 0 0 0 0 1.697l5.43 5.43-5.43 5.43a1.2 1.2 0 0 0 0 1.698l2.546 2.545a1.2 1.2 0 0 0 1.697 0l9.672-9.672z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </div>
    </div>
  );
}
