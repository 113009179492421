import React, { useState } from "react";
import "./loading.css";

/** Modify loading.css for colors, etc. */

export const LoadingDots: React.FC = () => {
  return (
    <div
      className="snippet"
      data-title="dot-flashing"
      style={{ width: "25px", marginLeft: "16px" }}
    >
      <div className="stage">
        <div className="dot-flashing"></div>
      </div>
    </div>
  );
};
