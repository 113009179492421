import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import HoverButton from "../components/hoverButton";
import logo from "../icons/YBUTTON.png";

function PlayCard({ onClick }: { onClick: (e: React.FormEvent) => void }) {
  return (
    <div
      style={{ width: "340px" }}
      className="backdrop-blur-md bg-black/35 rounded-2xl p-5 flex-col"
    >
      <div
        className="font-UI-primary text-white text-[42px] leading-[36px] mt-[16px]
        flex justify-center 
        mb-[30px] tracking-wide text-center"
      >
        FORTNITE TERMS
      </div>
      <HoverButton onClick={onClick}>
        <div className="mx-[10px] mt-[26px] my-[15px] p-3 text-[56px] font-UI-primary tracking-wider flex justify-center content-center items-center">
          {/* <div style={{ marginRight: "8px", marginLeft: "-4px", marginBottom: "10px" }}>
            <img
              src={logo}
              style={{ width: "30px", height: "30px" }}
            />
          </div> */}
          CHAT!
        </div>
      </HoverButton>
    </div>
  );
}

export default PlayCard;
