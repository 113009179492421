import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

function HoverButton2({
  classNameStyles,
  initialTextColor,
  disabled = false,
  text,
  url
}: {
  classNameStyles?: string;
  initialTextColor?: string;
  disabled?: boolean;
  text: string,
  url: string,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [textOffset, setTextOffset] = useState(0);

  const handleMouseEnter = () => {
    setIsHovered(true);
    // setTextOffset(-2.2);

    // setTimeout(() => {
    //   setTextOffset(0);
    // }, 80);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = (e: React.FormEvent) => {
    if (url && !disabled) {
      window.open(url, "_blank");
    }
  };

  return (
    <div
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      role={`${!disabled ? "button" : ""}`}
      style={{
        backgroundColor: isHovered ? "rgba(255, 255, 255, 1)" : "transparent",
        transition: "background-color 0.3s ease",
      }}
      className={`
        flex justify-center 
        outline-none ${
          isHovered &&
          !disabled &&
          `outline-[5px] outline-offset-[4px] hover:outline-white/80 transition-[outline-color] duration-150 ease-in-out`
        }

        ${initialTextColor ? `text-${initialTextColor} hover:text-black hover:opacity-100` : `text-black/0 hover:text-black/90 hover:opacity-100`}
      
      ${twMerge(`relative 
        rounded-2xl
      text-black text-xl

      ${classNameStyles ?? ""}
      `)}`}
    >
      <div
        style={
          !disabled
            ? {
                transition: "transform 0.05s ease-in-out",
                transform: `translateY(${textOffset}px)`,
              }
            : {}
        }
      >
        <div className="mx-[10px] mt-[6px] p-3 text-[32px] font-UI-primary tracking-wider text-nowrap">
            {text}
          </div>
      </div>
    </div>
  );
}

export default HoverButton2;
