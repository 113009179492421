import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import HoverButton from "../components/hoverButton";
import vbucks from "../icons/Vbucks.png";
import pfp from "../icons/pfp.png";

function getRandom4DigitNumber() {
  const min = 1000; // Minimum 4-digit number
  const max = 9999; // Maximum 4-digit number

  // Generate a random number between min and max (inclusive)
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

  return randomNumber;
}

// Place outside of render
const RAND_NUM = getRandom4DigitNumber();

function TopRight() {
  return (
    <div className="flex justify-center items-center gap-3">
      <img src={vbucks} style={{ width: "42px", height: "42px" }} />
      <div className="font-UI-primary text-white text-[36px] leading-[36px] tracking-wider mt-[7px] mr-[15px]">
        {RAND_NUM}
      </div>
      <img
        className="drop-shadow-lg"
        src={pfp}
        style={{ width: "73px", height: "73px" }}
      />
    </div>
  );
}

export default TopRight;
