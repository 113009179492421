/**
 * If you're ever getting type errors and you want to silence, just add // @ts-ignore above the line
 *
 * FONTS:
 * place file named fonts.otf in src/fonts
 *
 * BACKGROUND VIDEO:
 * place file named video.mp4 in src/video
 *
 * BACKGROUND IMAGE (if no video):
 * place background.jpg in public
 */

export const config = {
  // coin name
  // coinName: "COIN",
  // coinNameStyles: {
  //   fontSize: "200px",
  //   fontWeight: "200",
  //   color: "red",
  // },

  // icons & links
  telegramLink: "https://google.com",
  twitterLink: "https://google.com",
  pumpLink: "https://google.com",
  iconRowStyles: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "5vw",
  },

  // wallet address
  walletAddress: "2E1URj6hcfiL1o9JbNhKXD0pVYj6ZS8Z2W6K2Ndpump",
  walletAddressStyles: {
    fontSize: "32px",
  },

  // video background
  repeatVideoFromSeconds: 10,

  backgroundColor: "black",
  backgroundStyles: {
    height: "100vh",
    width: "100vw",
    backgroundSize: "cover", // stretch to fill the screen
    backgroundPosition: "center",
  },
  textColor: "black",
  iconColor: "black",
  iconSize: 54,

  // https://fonts.google.com/
  // just copy paste the font name here
  googleFontFamily: "Jersey 10", // unused right now
};
