import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import HoverButton from "../components/hoverButton";
import SearchIcon from "../icons/SearchIcon";
import BoxesIcon from "../icons/BoxesIcon";
import HoverButton2 from "../components/hoverButton2";

const dexLink = `https://google.com`;
const twitterLink = `https://google.com`;
const telegramLink = `https://google.com`;

function TopNav1() {
  return (
    <div
      className="backdrop-blur-md bg-black/20 rounded-xl px-6 py-2 flex gap-[26px] justify-center items-center 
    mix-blend-soft-light
    "
    >
      <div className="mr-[15px]">
        <BoxesIcon fillColor="fill-white" size={30} />
      </div>
      <div className="mr-[15px]">
        <SearchIcon fillColor="fill-white" size={30} />
      </div>
      <HoverButton
        onClick={() => {}}
        color="white"
        disabled
        classNameStyles="rounded-xl mix-blend-normal"
      >
        <div className="mx-[10px] mt-[6px] p-3 text-[32px] font-UI-primary tracking-wider">
          PLAY
        </div>
      </HoverButton>
      <HoverButton2
        initialTextColor="white"
        classNameStyles="rounded-xl"
        text="TWITTER"
        url={twitterLink}
      />
      {/* <HoverButton2
        initialTextColor="white"
        classNameStyles="rounded-xl"
        text="TELEGRAM"
        url={telegramLink}
      /> */}
    </div>
  );
}

function TopNav15() {
  return (
    <div
      className="bg-black/[.06] rounded-xl px-6 py-2 flex gap-[26px] justify-center items-center 
    mix-blend-soft-light
    "
    >
      <div className="mr-[15px]">
        <BoxesIcon fillColor="fill-white" size={30} />
      </div>
      <div className="mr-[15px]">
        <SearchIcon fillColor="fill-white" size={30} />
      </div>
      <HoverButton
        onClick={() => {}}
        color="white"
        disabled
        classNameStyles="rounded-xl mix-blend-normal"
      >
        <div className="mx-[10px] mt-[6px] p-3 text-[32px] font-UI-primary tracking-wider">
          PLAY
        </div>
      </HoverButton>
      <HoverButton2
        initialTextColor="white"
        classNameStyles="rounded-xl"
        text="TWITTER"
        url={twitterLink}
      />
      {/* <HoverButton2
        initialTextColor="white"
        classNameStyles="rounded-xl"
        text="TELEGRAM"
        url={telegramLink}
      /> */}
    </div>
  );
}

function TopNav2({ onClick }: { onClick: (e: React.FormEvent) => void }) {
  return (
    <div className="relative rounded-xl px-6 py-2 flex gap-[26px] justify-center items-center">
      {/* Background wrapper with mix-blend mode */}
      <div className="absolute inset-0 bg-black/0 mix-blend-soft-light rounded-xl pointer-events-none"></div>

      {/* Content that should not be affected by the blending */}
      <div className="relative z-10 flex gap-[26px] justify-center items-center">
        <div className="mr-[15px]">
          <BoxesIcon fillColor="fill-white/0" size={30} />
        </div>
        <div className="mr-[15px]">
          <SearchIcon fillColor="fill-white/0" size={30} />
        </div>
        <HoverButton
          onClick={onClick}
          color="white"
          disabled
          classNameStyles="rounded-xl"
        >
          <div className="mx-[10px] mt-[6px] p-3 text-[32px] font-UI-primary tracking-wider">
            PLAY
          </div>
        </HoverButton>
        <HoverButton2
          classNameStyles="rounded-xl"
          text="TWITTER"
          url={twitterLink}
        />
        {/* <HoverButton2
          classNameStyles="rounded-xl"
          text="TELEGRAM"
          url={telegramLink}
        /> */}
      </div>
    </div>
  );
}

function TopNav({ onClick }: { onClick: (e: React.FormEvent) => void }) {
  return (
    <div>
      <div className="absolute pointer-events-none">
        <TopNav1 />
      </div>
      <div className="absolute pointer-events-none">
        <TopNav15 />
      </div>
      <div className="absolute pointer-events-none">
        <TopNav15 />
      </div>
      <div>
        <TopNav2 onClick={onClick} />
      </div>
    </div>
  );
}

export default TopNav;
