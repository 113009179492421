import React, { useState } from "react";

function LevelBadge() {
  return (
    <div className="flex justify-center items-center w-[102px] h-[102px] rounded-full bg-transparent relative tracking-wider">
      <div className="absolute inset-0 rounded-full border-[9px] border-[#f7ff19]"></div>
      <div className="flex flex-col">
        <span className="text-[24px] leading-[24px] font-bold italic text-white z-10 font-UI-primary text-center mr-[2px]">
          SOL
        </span>
        <span className="text-[36px] leading-[36px] font-bold text-white z-10 font-UI-primary text-center">
          999
        </span>
      </div>
    </div>
  );
}

function BottomRight() {
  return (
    <div className="flex flex-col gap-3">
      <div className="w-auto backdrop-blur-md bg-white/20 rounded-2xl p-3">
        <LevelBadge />
      </div>
      {/* <div className="w-auto backdrop-blur-md bg-white/20 rounded-2xl p-2">
        <div className="flex justify-center items-center w-[84px] h-[84px] rounded-full bg-transparent relative tracking-wider"></div>
      </div> */}
    </div>
  );
}

export default BottomRight;
