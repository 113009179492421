export default function SearchIcon({
  fillColor,
  size,
}: {
  fillColor: string;
  size: number;
}) {
  return (
    <svg
      className={`${fillColor}`}
      width={`${size}`}
      height={`${size}`}
      viewBox="0 0 162 162"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M69 0H0V68H69V0ZM94 0V68H162V0H94ZM162 93H94V162H162V93ZM69 162V93H0V162H69Z"
      />
    </svg>
  );
}
