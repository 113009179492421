import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

function HoverButton({
  classNameStyles,
  children,
  color = "#f7ff19",
  onClick,
  disabled = false,
}: {
  classNameStyles?: string;
  children: React.ReactNode;
  color?: string;
  onClick: (e: React.FormEvent) => void;
  disabled?: boolean;
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [textOffset, setTextOffset] = useState(0);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setTextOffset(-2.7);

    setTimeout(() => {
      setTextOffset(0);
    }, 80);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      role={`${!disabled ? "button" : ""}`}
      style={{backgroundColor: color}}
      className={`
        flex justify-center 
        outline-none ${
          isHovered &&
          !disabled &&
          `outline-[5px] outline-offset-[4px] hover:outline-white/80 transition-[outline-color] duration-150 ease-in-out`
        }
      
      ${twMerge(`relative 
        rounded-2xl
      text-black text-xl

      ${classNameStyles ?? ""}
      `)}`}
    >
      <div
        style={
          !disabled
            ? {
                transition: "transform 0.05s ease-in-out",
                transform: `translateY(${textOffset}px)`,
              }
            : {}
        }
      >
        {children}
      </div>
    </div>
  );
}

export default HoverButton;
