import React, { useEffect, useState, useCallback } from "react";
import { config } from "./config/config";
import "./App.css";
import { IconFooter } from "./components/IconRow";
import { motion, AnimatePresence } from "framer-motion";
import { isMobile } from "./utils/utils";
import { ChatUI } from "./UI/chatUI";
import OpenAI from "openai";
import HoverButton from "./components/hoverButton";
import PlayCard from "./UI/playCard";
import TopNav from "./UI/topNav";
import TopRight from "./UI/topRight";
import BottomRight from "./UI/bottomRight";
import FortniteViewer from "./components/model";
import CA from "./UI/CA";

const App: React.FC = () => {
  const [foldArms, setFoldArms] = useState(false);

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  // Need to zoom for different screen sizes
  const zoomFactor = `${Math.max(
    (screenHeight / 1294) * 100,
    75
  )}%`;
  // Keep fornite guy 100%, negate above scaling
  const fortniteViewerZoomFactor = `${Math.min(
    (1 / (screenHeight / 1294)) * 100,
    133
  )}%`;

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/* <IconFooter /> */}
      {/* Page content */}
      <div
        // NOTE: this background style is important for mobile
        className={`
        h-dvh w-screen
        overflow-hidden
        flex gap-3
        bg-transparent
        `}
        style={{
          fontFamily: "FortniteSecondary",
          color: config.textColor,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className="w-full flex justify-between p-10 gap-6"
          style={{
            zoom: zoomFactor,
          }}
        >
          <div className="flex-none">
            <TopNav onClick={() => {}} />
          </div>

          <div className="shrink">
            <CA />
          </div>
          <div className="flex-none">
            <TopRight />
          </div>
        </div>
        <div
          className="w-full h-full flex justify-between p-10 gap-4"
          style={{
            zoom: zoomFactor,
          }}
        >
          <div className={`w-[55%] flex flex-col justify-between pb-[7%]`}>
            <AnimatePresence>
              {foldArms && (
                <div
                  className={`flex flex-col place-content-start items-start w-full`}
                >
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0, duration: 0.5 }}
                    exit={{ opacity: 0 }}
                    className="w-full"
                  >
                    <ChatUI />
                  </motion.div>
                </div>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {!foldArms && (
                <div
                  className={`h-full flex flex-col place-content-end items-start`}
                >
                  <motion.div
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0, duration: 0.5 }}
                    exit={{ opacity: 0 }}
                  >
                    <PlayCard
                      onClick={() => {
                        setFoldArms(true);
                      }}
                    />
                  </motion.div>
                </div>
              )}
            </AnimatePresence>
          </div>
          <div
            className="w-[35%] flex justify-center -z-10 overflow-visible items-end"
            style={{
              zoom: fortniteViewerZoomFactor,
            }}
          >
            <FortniteViewer foldArms={foldArms} />
          </div>
          <div className="w-[15%] flex flex-col place-content-end items-end pb-[7%]">
            <BottomRight />
          </div>
        </div>
      </div>

      {/* static background */}
      <div
        style={{
          backgroundColor: config.backgroundColor,
          backgroundImage: `url(${process.env.PUBLIC_URL}/background.jpeg)`,
          zIndex: "-100",
          position: "fixed",
          top: 0,
          left: 0,
          ...config.backgroundStyles,
        }}
      />
    </>
  );
};

export default App;
