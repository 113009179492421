import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import HoverButton from "../components/hoverButton";
import SearchIcon from "../icons/SearchIcon";
import BoxesIcon from "../icons/BoxesIcon";
import HoverButton2 from "../components/hoverButton2";

const ca = "CA: 2fRVu3civercZRRGMVBmZZ5Cy1LnZdpJyvfafaKTpump";

function TopNav1() {
  return (
    <div
      className="backdrop-blur-md bg-black/20 rounded-xl px-2 py-2 flex gap-[26px] justify-center items-center 
    mix-blend-soft-light
    "
    >
      <HoverButton2
        initialTextColor="white"
        classNameStyles="rounded-xl"
        text={ca}
        url={"CA"}
        disabled
      />
    </div>
  );
}

function TopNav15() {
  return (
    <div
      className="bg-black/[.06] rounded-xl px-2 py-2 flex gap-[26px] justify-center items-center 
    mix-blend-soft-light
    "
    >
      <HoverButton2
        initialTextColor="white"
        classNameStyles="rounded-xl"
        text={ca}
        url={"CA"}
        disabled
      />
    </div>
  );
}

function TopNav2() {
  return (
    <div className="relative rounded-xl px-2 py-2 flex gap-[26px] justify-center items-center">
      {/* Background wrapper with mix-blend mode */}
      <div className="absolute inset-0 bg-black/0 mix-blend-soft-light rounded-xl pointer-events-none"></div>
      <HoverButton2
        classNameStyles="rounded-xl"
        text={ca}
        url={"CA"}
        disabled
      />
    </div>
  );
}

function CA() {
  return (
    <div>
      <div className="absolute pointer-events-none">
        <TopNav1 />
      </div>
      <div className="absolute pointer-events-none">
        <TopNav15 />
      </div>
      <div className="absolute pointer-events-none">
        <TopNav15 />
      </div>
      <div>
        <TopNav2 />
      </div>
    </div>
  );
}

export default CA;
